import { render, staticRenderFns } from "./index.vue?vue&type=template&id=59d2daa9"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroTabSlider: require('/srv/app/components/hero/TabSlider.vue').default,SectionsOverviewBox: require('/srv/app/components/sections/OverviewBox.vue').default,SectionsProductFinder: require('/srv/app/components/sections/ProductFinder.vue').default,SectionsCardsAction: require('/srv/app/components/sections/CardsAction.vue').default,SectionsCardsNumbers: require('/srv/app/components/sections/CardsNumbers.vue').default,SectionsTestimonials: require('/srv/app/components/sections/Testimonials.vue').default,BannerProductFinder: require('/srv/app/components/banner/ProductFinder.vue').default,BannerProductCompare: require('/srv/app/components/banner/ProductCompare.vue').default,BannerCallUs: require('/srv/app/components/banner/CallUs.vue').default,BannerBusinessPartner: require('/srv/app/components/banner/BusinessPartner.vue').default,BannerMoreInfo: require('/srv/app/components/banner/MoreInfo.vue').default,CardSubscribe: require('/srv/app/components/card/CardSubscribe.vue').default})
